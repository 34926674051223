//import img1 from "/img/fri023_outdoor_01.jpg"
// import img2 from "../img/fri023_outdoor_02.jpg"
// import img3 from "../img/fri023_outdoor_03.jpg"
// import img4 from "../img/fri023_pool_02.jpg"
// import img5 from "../img/fri023_pool_03.jpg"
// import img6 from "../img/fri023_pool_04.jpg"
// import img7 from "../img/fri023_pool_05.jpg"
// import img8 from "../img/fri023_view_01.jpg"
// import img9 from "../img/fri023_view_02.jpg"
// import img9_1 from "../img/view1.jpg"
// import img9_2 from "../img/view2.jpg"
// import img9_3 from "../img/view3.jpg"

// import img10 from "../img/kitchen.jpg"
// import img11 from "../img/kitchen2.jpg"
// import img12 from "../img/living.jpg"
// import img13 from "../img/living2.jpg"
// import img14 from "../img/living3.jpg"
// import img15 from "../img/living4.jpg"
// import img16 from "../img/living5.jpg"

// import img17 from "../img/chambre.jpg"
// import img18 from "../img/chambre2.jpg"
// import img19 from "../img/chambre3.jpg"
// import img20 from "../img/sdb.jpg"
// import img21 from "../img/sdb2.jpg"



export const rezImg = [

]
export const presentationImg=
    [
        "/img/outside2.jpg",
        "/img/outside3.jpg",
        "/img/outside4.jpg",
        "/img/outside5.jpg",
        "/img/outside1.jpg",
        "/img/cuisine2.jpg",
        "/img/kitchen_view_2.jpeg",
        "/img/sallon.jpg",
        "/img/sallon2.jpg",
        "/img/sallon3.jpg",
        "/img/sam.jpg",
        "/img/sam2.jpg",
        "/img/chambre1.jpg",
        "/img/chambre1_2.jpg",
        "/img/chambre2_1.jpg",
        // "/img/sdb2.jpg",
        "/img/sdb3.jpg",
        "/img/sdb_view_1.jpeg",
        "/img/vue1.jpg",
        "/img/vue2.jpg",
        "/img/vue3.jpg",
        "/img/vue4.jpg",
        "/img/vue5.jpg",
        "/img/piscine.jpg",


      ]

export const chambreImg=[
    "/img/chambre1.jpg",
    "/img/chambre1_2.jpg",
    "/img/chambre2.jpg",
    "/img/chambre2_1.jpg",
    //"/img/sdb1.jpg",
    "/img/sdb2.jpg",
    "/img/sdb3.jpg",
    "/img/sdb4.jpg",

]

export const loginImg = "/img/illustration_dashboard.png"

Date.prototype.addDays = function(days) {
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
}

export const getDates = (f_date, t_date) => {
    var dateArray = new Array();
    var currentDate = f_date;
    while (currentDate < t_date) {
        dateArray.push(new Date (currentDate));
        currentDate = currentDate.addDays(1);
    }
    return dateArray;
}

export const getStatusColor = (data) => {
    return data ? "green": "red"
}

const isDateBlocked = (post, blockedDates) => {
    const startDate = new Date(post.startDate);
    const endDate = new Date(post.endDate);
    let test = false
    for (let d = startDate; d <= endDate && !test; d.setDate(d.getDate() + 1)) {
        blockedDates.forEach(element => {
            if (formatDate(element) ===  formatDate(d)){
                test = true
            }
        });
    }
    return test;
  }  

export  const formatDate  = (d) => {
    try {
        d = new Date(d)
    } catch(error){
        console.log(error)
        return "Date non fournies"
    }
    var datestring = ("0" + d.getDate()).slice(-2) + "-" + ("0"+(d.getMonth()+1)).slice(-2) + "-" + d.getFullYear()
    return datestring;
}

export const getCardColor = (post, blocked) => {
    if (post.valid) {
        return 'green'
    } else {
        if (isDateBlocked(post, blocked)){
            return "orange"
        } else {
            return "yellow"
        }
    }
}



export const isPaid = (data) => {
    
    return data
    
}

