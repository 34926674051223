import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../utils/firebase";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";


function Navbar() {
    const [user, loading] = useAuthState(auth);
    const [logged, setLogged] = useState(false);

    useEffect(()=> {
        if (loading) return;
        if (user){
            setLogged(true)
        } else {
            setLogged(false)
        }
    }, [user])


    const logOut = () => {
        auth.signOut()
        toast.success("Déconnexion réussi", {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose:2000
           })
    }

    

    return (
        <nav className="navBar shadow-md">

        <input type="checkbox" id="checkbox_toggle" />
        <label for="checkbox_toggle" class="hamburger">&#9776;</label>
            <ul className="menu">
                {!logged?(
                    <div>
                    <li><a href="/" >Acceuil</a></li>
                    <li><a href="#rez" >Location</a></li>
                    <li><a href="#boxThis" >Réserver</a></li>

                    {/* <li><a href="#chambre" >Location Chambre</a></li> */}
                    <li><a href="#ville" >Localisation</a></li>

                    </div>
                )
                :(
                <div>

                    <li><a href="/admin" >Dashboard</a></li>
                    <li><a onClick={logOut} href="">Logout</a>
                
                </li>
                </div>
                )
                }
            </ul>            
        </nav>

    )
}

export default Navbar;