import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker, SingleDatePicker, DayPickerRangeController } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import "react-dates/initialize"; 
import React, { useEffect } from 'react';
import { useState } from 'react';
import { getDates } from '../helper/helper';
import moment from 'moment/moment';
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, FormGroup, TextField } from '@mui/material';
import getStripe from '../lib/getStripe';
import { Grid } from 'swiper';
import { collection, addDoc, serverTimestamp, orderBy, query, onSnapshot } from "firebase/firestore";
import { db } from "../utils/firebase"
import { v4 as uuidv4 } from 'uuid';
import { async } from '@firebase/util';
import axios from 'axios';
import { ArrowDropDown, CheckBox } from '@mui/icons-material';
import { useForm } from 'react-hook-form';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { config } from '../Config';

const MyDatePicker = ({data, blocked}) => {

  const url = config.url.API_URL;
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const defaultFocusedInput = "null";
  const [focusedInput, setFocusedInput] = useState(defaultFocusedInput);
  const [numberOfPeople, setNumberOfPeople] = useState(2)
  const [dis, setDis] = useState(true)
  const [showDialog, setShowDialog] = useState(false);
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [question, setQuestion] = useState('')
  const [locationType, setLocationType] = useState('rez')
  const [pricePreview, setPricePreview] = useState(null); 
  const [discount, setDiscount] = useState(null);
  const [conditionAccepted, setCondition] = useState(false);
  const [conditionDialog, setConditionDialog] = useState(false);
  const [hasBeenReaded, setRead] = useState(false);
  const [arrow, setArrow] = useState(false);
  const [roomOptions, setRoomOptions] = useState({
    parent: false,
    room1: false,
    room2: false
  });

  const client = axios.create({
    baseURL:config.url.API_URL,
    json:true
})


  useEffect(() => {
    if (startDate && endDate){
      getPriceForRoom(startDate, endDate);
    }
  }, [roomOptions]);


async function handleCheckout() {
  const stripe = await getStripe();
  const clientReferenceId = uuidv4();
  client.post("/posts",  {name:name, email:email, clientReferenceId:clientReferenceId, startDate:startDate._d, endDate:endDate._d,question:question})
  .then((response) => {
    console.log(response.data);
      //getPosts()
  
  })
  .catch((error) => {
    console.log(error);
  });
  const response = await client.post("checkout", { clientReferenceId, email:email, startDate:startDate._d, endDate:endDate._d, locationType:locationType, roomOptions});
  const sessionId = response.data.sessionId;
  const { error } = await stripe.redirectToCheckout({ sessionId });
  if (error) {
    console.log(error);
  }
}

const getPriceFromDate = async () => {
  return client.get("/price",{ params:{sd:startDate, ed:endDate}}).then((response)=>{
    if (response.data.status === "success"){
      console.log(response)
      console.log("SUCCESS : ", response.data.data)
      return response.data.data
      } else {
        console.log("ERROR : ", response.data.msg)
        return null;
      }
  }).catch((error)=> {
    console.log(error.response.data.msg)
    return 
  })

}



const isRangeBlocked = ({ startDate, endDate }) => {
  if (!startDate || !endDate) {
    return false; // no range selected, so no need to block any days

  }
  const start = startDate.clone();
  while (start.isSameOrBefore(endDate, 'day')) {
    if (isDayAlreadyChosen(start)) {
      setDis(true)
      return true;
    }
    start.add(1, 'day');
  }
  data(startDate, endDate)
  setDis(false)
  if (locationType === "rez") {
    const money = getPriceFromDate();
    money.then((value)=>{checkForDiscount(startDate, endDate, value)})
  } else{
    setDiscount(null)
    setPricePreview(null)
    getPriceForRoom(startDate, endDate)
  }
  return false;
};

const getPriceForRoom = (s, e) => {
  const diffInDays = s.diff(e, 'days');
  if (roomOptions.parent && roomOptions.room1 && roomOptions.room2){
    setPricePreview(-diffInDays * 100)
  } else if (roomOptions.parent && roomOptions.room1){
    setPricePreview(-diffInDays * 80)
  } else if (roomOptions.parent) {
    setPricePreview(-diffInDays * 60)
  }
}

const checkForDiscount = (s, e, pricePerDay) => {
  const numWeeks = moment(e).diff(moment(s), 'weeks');
  s = new Date(s)
  // Check if the difference is exactly 13 days
  console.log(pricePerDay)
  let dsc = null;
  let dscDisplay = null;
  const price = numWeeks*7*pricePerDay

  if (numWeeks === 2) {
    // User has selected exactly 2 weeks
      dsc = 0.05
      dscDisplay = 5
  } else if (numWeeks >= 3) {
    dsc = 0.1
    dscDisplay = 10
    // User has not selected exactly 2 weeks
  }
  const res = parseInt(price - price*dsc)
  setDiscount(dscDisplay)
  setPricePreview(res)


}


const isDayAlreadyChosen = (day) => {
  const disabledDates = blocked.map(dateString => new Date(dateString));
  const formattedDate = day.format('YYYY-MM-DD');
  const disabledDatesString = disabledDates.map(date => date.toISOString().substring(0, 10)); // convert Date objects to strings
  return disabledDatesString.includes(formattedDate);

}


const isDayBlocked = (day) => {
  const disabledDates = blocked.map(dateString => new Date(dateString));
  const formattedDate = day.format('YYYY-MM-DD');
  const disabledDatesString = disabledDates.map(date => date.toISOString().substring(0, 10)); // convert Date objects to strings
  const fromDB = disabledDatesString.includes(formattedDate); 

  const currentDate = moment(); // Get the current date
  if (day.isBefore(currentDate, 'day')) {
    return true; // Block days in the past
  }

  if (locationType === "rez") {
    if (!fromDB){
      return !(day.day() === 6);
    }
  }
  return fromDB
};


const handleConfirmDates = () => {
  setShowDialog(true);
};

const handleDialogClose = () => {
  setShowDialog(false);
};

const openDialog = () => {
  setConditionDialog(true);
}

const closeDialog = () => {
  setConditionDialog(false);
}

const readAndAccteped = () => {
  setCondition(true);
  setConditionDialog(false);
}




const handleFormSubmit = (event) => {
  event.preventDefault();
  // handle form submission here
  console.log(`Name: ${name}`);
  console.log(`Email: ${email}`);
  console.log(`Question: ${question}`);
  handleDialogClose();
};

const handlePersonChange = (selectedValue) =>  {
  console.log(`Selected value is: ${selectedValue}`);
  setNumberOfPeople(selectedValue)
  // Do something with the selected value here
}

const handleLocationType = (selectedValue) => {
  console.log(`Selected value is: ${selectedValue}`);
  setPricePreview(null)
  setDiscount(null)
  setLocationType(selectedValue);
}

const handleCheckboxChange = (event) => {
  setPricePreview(null)
  setRoomOptions({ ...roomOptions, [event.target.name]: event.target.checked });
};

const toggleCollapse = () => {
  setArrow(!arrow);
}
const orientation = window.matchMedia("(max-width: 700px)").matches ? 'vertical' : 'horizontal'

    return (
      
      <div>
        <p className='text-lg text-center mt-2 mb-2 collapsible'  onClick={toggleCollapse}>Sélectionnez les dates {!arrow ? <ArrowDropUpIcon/> : <ArrowDropDownIcon/>}</p>
        <div className={`content ${arrow ? 'hidden' : ''}`}>
          <div className='dateHeader'>
          <DateRangePicker
            startDate={startDate}
            startDateId="start_date_id"
            endDate={endDate}
            endDateId="end_date_id"
            readOnly={true}
            orientation={orientation}
            onDatesChange={({startDate, endDate})=> {
              if (isRangeBlocked({startDate, endDate})){
                setStartDate(null);
                setEndDate(null);
              } else {
                setStartDate(startDate);
                setEndDate(endDate);
                
                
              }
            }}
            focusedInput={focusedInput || defaultFocusedInput}
            onFocusChange={(focusedInput) => setFocusedInput(focusedInput)}
            displayFormat={"DD-MM-YYYY"}
            startDatePlaceholderText={"Arrivé"}
            endDatePlaceholderText={"Départ"}
            anchorDirection={"right"}
            openDirection={"down"}
            isOutsideRange={isDayBlocked}
            isDayBlocked={isDayBlocked}
            />
          {pricePreview !== null ? (
          <div className='price'>
              {discount !== null ? (
              <div className='price'>
                <div className='text-xs text-right m-2'>
                  Prix total :   
                  <div className='prev-price'>{parseInt(pricePreview + discount*pricePreview*0.01)} € </div>
                  <div className='discount'>{discount}%</div>

                  <div className='new-price'>{pricePreview} € </div>
                </div>
              </div>
            ): 
            (
              <p className='text-xs text-right m-2'>Prix total : {pricePreview} €</p>
              )}
          </div>
        ): 
         (
          <div className='price-info'>
            <p className='text-xs text-right m-2'>Pour voir le prix exact, veuillez entrer vos dates</p>
          </div>
        )}

        </div>
        <div className='select'>
        <label for="person" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Choisissez le nombre de personne</label>
        <select 
          id="person" 
          class="select select-number bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg w-full focus:ring-blue-500 focus:border-blue-500 block  p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          onChange={(event) => handlePersonChange(event.target.value)}

          >
          <option selected>2</option>
          <option value="3">3</option>
          <option value="4">4</option>
          <option value="5">5</option>
          <option value="6">6</option>
          <option value="7">7</option>
        </select>
        </div>
        {/* <div className='select'>
        <label for="location" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Type de location</label>
        <select 
          id="location" 
          class="select select-number bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg w-full focus:ring-blue-500 focus:border-blue-500 block  p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          onChange={(event)=> handleLocationType(event.target.value)}
          >
          <option selected value="rez">Location rez de chaussée</option>
          <option value="chambre">Location chambre</option>
        </select>

        </div> */}
        <div className='contitions-form'>
        <p >Veuillez accepter <br/><button onClick={openDialog} className='conditions-link'>les conditions générales</button></p>
        </div>
        

        {locationType === "chambre" ? <div className='select'>
        <FormGroup>
          <FormControlLabel
            control={<Checkbox checked={roomOptions.parent} onChange={handleCheckboxChange} name="parent" />}
            label="Chambre parentale"
          />
          <FormControlLabel
            control={<Checkbox checked={roomOptions.room1} onChange={handleCheckboxChange} name="room1" />}
            label="Chambre 1"
          />
          <FormControlLabel
            control={<Checkbox checked={roomOptions.room2} onChange={handleCheckboxChange} name="room2" />}
            label="Chambre 2"
          />
        </FormGroup>

        </div> 
        : <div/>}

        <div className='center-btn'>
          <Button disabled={dis || !conditionAccepted} onClick={handleConfirmDates} variant='outlined' >Continuer</Button>
        </div>
        </div>
      <Dialog className='text' open={showDialog} onClose={handleDialogClose}>
        <DialogTitle>Confirmation de la réservation ({startDate?.format('D/M/YYYY')} - {endDate?.format('D/M/YYYY')})</DialogTitle>
        <DialogContent>
          <form className='popup' onSubmit={handleFormSubmit}>
            <div className='textfieldDiv'>
              <TextField className='textfield' label="Nom" value={name} onChange={(e) => setName(e.target.value)}/>
              <TextField className='textfield' label="Email" value={email} onChange={(e) => setEmail(e.target.value)}/>
            </div>
            <div className='textArea'>
              <textarea value={question} onChange={(e)=>{setQuestion(e.target.value)}} placeholder="Ecrivez votre message..."></textarea>

              {/* <TextField className='questionfield' label="Question" value={question} onChange={(e) => setQuestion(e.target.value)} /> */}
            </div>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCheckout}>Continuer</Button>
          <Button onClick={handleDialogClose}>Annuler</Button>
        </DialogActions>
      </Dialog>

      <Dialog className='text' scroll='body' open={conditionDialog} onClose={closeDialog}>
        <DialogTitle>Termes et conditions</DialogTitle>
        <DialogContent className='conditions-paragraph'>
        <p>
  • Caution: Caution de 500€ à faire par virement bancaire sur le numéro de compte suivant avec la communication "Caution-NOM-période de location". La réservation sera confirmée dès réception du paiement du séjour ainsi que la réception de la caution. 
  <br/>HAP / EID 
  IBAN: BE25 0637 3262 6382 
  BIC: GKCCBEBB 
  <br/><br/>
  Le virement de la caution doit être indépendant du paiement de la location. La caution vous sera reversée dans les 15 jours suivants la fin de votre séjour. Une partie ou la totalité de la caution sera retenue en cas de dégâts matériels et de non respect de la charte. L'état des lieux se fera le dernier jour de votre séjour, avant ou après votre départ. En réservant, vous vous engagez à signaler tout dégât matériel dont vous êtes responsable. Si ceux-ci dépassent le montant de la caution, vous vous engagez à rembourser les frais de remplacement ou de réparation.
  <br/><br/>
  Condition d'annulation : Vous pouvez annuler sans frais jusqu'à 2 mois avant la date de la réservation. 
  <br/>
  &#60;2 mois: 30% du montant total de la location sera retenu
  <br/>
  &#60;1 mois: aucun remboursement possible 
  <br/><br/>
  • Animaux: L'accès aux chambres, aux canapés et a la piscine est strictement interdit aux animaux. Vous êtes responsables de ramasser les déjections dans le jardin et nettoyer/ remplacer/ réparer tous dégâts causés par les animaux. Tout manquement sera motif d'une retenue sur la caution.
  <br/><br/>
  • Sécheresse: Il est obligatoire de refermer le dôme de la piscine lorsque celle-ci n'est pas utilisée afin d'éviter toute évaporation. En effet, les restrictions d'eau suite à la sécheresse importante interdisent le remplissage de la piscine. Nous vous demandons une grande prudence quant à l'utilisation de l'eau et de respecter les restrictions.
  <p>Nous vous demandons également d’être prudent lors de l’utilisation du barbecue suite à la sécheresse, afin d’éviter tout départ de feu. Il est souhaitable de placer à votre disposition un bidon d’eau en cas de problème. 
Il en est de même pour les cigarettes, svp ne pas cendrer dans l’herbe sèche et utiliser un cendrier.   
Le jardin ne peut être arrosé en période de sécheresse.</p>


  <p>• Piscine&nbsp;: Le propriétaire peut passer pour mettre les pastilles de chlore dans la piscine et vérifier le niveau de l’eau.</p>
  <p>Il est obligatoire de rincer la crème solaire avant d’entrer dans l’eau, en utilisant la douche extérieure, pour préserver la qualité de l’eau. Si le dôme reste ouvert, laisser l'échelle pour éviter tous riques de noyade pour les animaux.</p>
  <p>La piscine n’est pas accessible en période d’hivernage (± de octobre à avril)</p>

<p>• Services&nbsp;: Le ménage ainsi que le lavage et repassage des draps/serviettes de bain est effectué par nos soins.
Le lave-vaisselle doit être vidé avant votre départ et la vaisselle doit être propre.</p>

<p> • Stores&nbsp;: laisser libre l’espace sous les stores pour qu’il n’y ait pas de problème de fermeture.</p>
• Autres
- Il est interdit de fumer dans la maison. <br/>
- Si le détecteur de monoxyde de carbone sonne, ouvrez immédiatement les fenêtres.<br/>
- Fermer les fenêtres et verrouiller les portes la nuit ainsi que lors des sorties. <br/>
- Ne pas laisser les clés sur la porte d’entrée. <br/>
- Vous êtes tenus de remplacer / rembourser ce que vous avez cassé ou abimé (vaisselle, linge de maison déchiré ou tâché…). <br/>
- Laisser l’espace libre sous les stores pour éviter de les endommager lors de leur fermeture.
<br/><br/>
• Départ
- Le départ s’effectue avant 10h du matin. <br/>
- Fermer toutes les fenêtres et verrouiller la véranda ainsi que la porte d’entrée. <br/>
- Fermer tous les stores. <br/>
- Fermer le dôme de la piscine. <br/>

- Le lave-vaisselle doit être vidé avant votre départ et la vaisselle doit être propre et rangée. <br/>
- Le réfrigérateur doit être vide. <br/>
- Le barbecue doit être nettoyé avant votre départ. <br/>
- Toutes les poubelles doivent être jetées avant votre départ. <br/>
- Nous vous remercions pour le respect de ces règles.

</p>

        </DialogContent>
        <DialogActions>
          <Button onClick={readAndAccteped}>J'ai lu et j'accepte les conditions</Button>
        </DialogActions>
      </Dialog>



      </div>
      

    )
}

export default MyDatePicker;