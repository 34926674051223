import MyDatePicker from "../Components/DatePick";
import { collection, addDoc, serverTimestamp, orderBy, query, onSnapshot } from "firebase/firestore";
import { db } from "../utils/firebase"
import { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify"
import 'react-toastify/dist/ReactToastify.css';
import { useSearchParams } from 'react-router-dom'
import GoogleMap from "../Components/Map"
import CarouselV2 from "../Components/CarouselV2"
import { chambreImg, presentationImg, rezImg } from "../helper/helper"
import axios from "axios";
import { TextField } from "@mui/material";
import { config } from "../Config";

const Home = () => {
  const url = config.url.API_URL;
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const [num, setNum] = useState("")
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [blockedDates, setBlockedDates] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams()

  useEffect(()=> {
      getBlockedDates();
      if (searchParams.get("success") === "true"){
        toast.success("Le payement a correctement été efféctué", {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose:2000
         })
      } else if (searchParams.get("success") === "false"){
        toast.error("Une erreur est survenue lors du payement", {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose:2000
         })
      }
      setSearchParams("")
}, [])

  




  // retrieve blocked date from firestore
  const getBlockedDates = async() => {
    const dateUrl = url+"dates/"
    axios.get(dateUrl)
    .then(response => {
        if (response.data.status === "success") {
        const dates = response.data.data;
        console.log(`Retrieved ${dates.length} dates from the server:`, dates);
        // Do something with the dates array
        setBlockedDates(dates)            

        } else {
        console.error("Error retrieving dates:", response.data.errorMsg);
        }
    })
    .catch(error => {
        console.error("Error retrieving dates:", error);
    });
}





const updateDate = (f_date, s_date) => {
  setFromDate(f_date._d)
  setToDate(s_date._d)
}





    return (

        <div>
            <ToastContainer/>
            <div className="banner"
                 style={{
                     backgroundImage: "linear-gradient(rgba(0,0,0,0.4),rgba(0,0,0,0.4)), url(" + presentationImg[3] + ")",
                     backgroundSize: "cover",
                     backgroundPosition: "center",
                     backgroundRepeat: "no-repeat",
                     height: "100vh",
                     position: "absolute",
                     top: "0",
                     left: "0",
                     right: "0",
                     bottom: "0",
                     zIndex: "1000"

                 }}>

                <div className="container">
                    <h1
                        style={{
                            color: "white",
                            fontSize: "2.5rem",
                            fontWeight: "700",
                            lineHeight: "1.2",
                            letterSpacing: "0.1rem",
                            textTransform: "uppercase",
                            textAlign: "center",
                            paddingTop: "40vh"

                        }}
                    >une <strong>maison </strong> pour des vacances <strong>exceptionnelles</strong>!</h1>
                    <p
                        style={{
                            color: "white",
                            fontSize: "1.5rem",
                            fontWeight: "400",
                            lineHeight: "1.2",
                            letterSpacing: "0.1rem",
                            textTransform: "uppercase",
                            textAlign: "center",
                            paddingTop: "2vh"

                        }}
                    >Profitez d'une location dans un cadre paisible</p>
                    <a style={{
                        color: "white",
                        fontSize: "1rem",
                        fontWeight: "400",
                        lineHeight: "1.2",
                        letterSpacing: "0.1rem",
                        textTransform: "uppercase",
                        textAlign: "center",
                        paddingTop: "2vh",
                        display: "block",
                        width: "200px",
                        position: "absolute",
                        left: "calc(50% - 100px)",
                        bottom: "10px",
                        padding: "10px",
                        borderRadius: "5px",
                        textDecoration: "none",
                        backgroundColor: "#3498db"

                    }} href="#main-content">Plus d'informations</a>
                </div>
            </div>
            <div
                style={{marginTop: "100vh"}}
                >

            <CarouselV2 images={presentationImg} NumberDisplayed={2.3}/>
            </div>
            <div className="container first">


            </div>


            {/* <div id="rez" className="container title">
      <p >Location rez-de-chaussée</p>
</div> */}
            {/* <CarouselV2 images={rezImg} NumberDisplayed={2.4}/> */}
            <div className=" header"

                 id="main-content"
            >
                <h1>
              <span>Bienvenue chez Hapeiday's !<br/>
              <div className="location">
              <i className="hp-icon fas fa-map-marker-alt"></i>
              <span className="text-center">Corps, France</span>

              </div>


          </span>
                </h1>

            </div>
            <div className="description-container container">
                <div className="description container">
                    <p
                        style={{
                            fontSize: "1.2rem",
                            lineHeight: "1.2",
                            letterSpacing: "0.1rem",
                            paddingTop: "2vh"


                        }}
                    >
                        Vous êtes à la recherche d’une location de vacances dans un lieu paisible ? Nous vous proposons
                        notre magnifique maison située à Corps en Isère, pour vous permettre de vous ressourcer et de
                        profiter pleinement de la nature. Elle se situe à deux pas du Lac du Sautet, qui propose des
                        activités tout l’été. En hiver, vous pourrez profiter des stations de ski aux alentours.<br/><br/>

                        Cette maison offre une vue imprenable sur les montagnes.<br/><br/>

                        La maison dispose de toutes les commodités nécessaires pour vous assurer un confort optimal,
                        tout en étant proche des centres d'intérêts locaux. Vous pourrez profiter de la terrasse plein
                        Sud avec une vue magnifique sur les Alpes, ainsi que de la piscine privée. Des transats et des
                        parasols sont à votre disposition. Vous pourrez également profiter d’un barbecue pour
                        d’agréables soirées grillades.<br/><br/>

                        Vous louez le rez-de-chaussée entier. Il est composé de trois chambres confortables dont une
                        suite parentale équipée d’une salle de bain et de toilettes. Chaque chambre dispose d’un lit
                        double dont une avec un lit simple supplémentaire. Vous aurez accès à une deuxième salle de bain
                        et toilettes.<br/><br/>

                        Le linge de maison est fourni.<br/><br/>

                        La cuisine est entièrement équipée, et donne accès à un grand salon / salle à manger ainsi
                        qu’une véranda spacieuse qui offre une vue magnifique sur les montagnes.<br/><br/>

                        Les propriétaires vivent à l’étage et se font très discrets. Ils sont à votre disposition en cas
                        de problème. Le hall d’entrée est commun.
                    </p>
                    <hr className="h-px my-8 bg-gray-200 border-1 dark:bg-gray-700"></hr>
                </div>
                <div id="boxHere"></div>
                <div className="datePicker bg-white shadow-lg rounded px-8 pt-6 pb-8 mb-4" id="boxThis">
                    <MyDatePicker data={updateDate} blocked={blockedDates} className="MyDatePicker"/>
                </div>
            </div>

            {/* <div id="chambre" className="title">
  <p >Location chambre</p>
</div>
<div className="crop">
  <CarouselV2 images={chambreImg} NumberDisplayed={2}/>
</div>
<div className="description-container container">
  <div className="description">
      <p>
      Il est également possible de louer les chambres séparément. Les trois chambres sont toutes équipées d'un lit double, avec une suite parentale comprenant une salle de bain privative. Chaque chambre est également équipée d'un rangement et d'un accès à la cuisine et au salon magnifiquement décorés. Que vous soyez un petit groupe ou un voyageur individuel, vous êtes les bienvenus pour séjourner dans l'une des chambres de notre maison. Remplissez le formulaire pour plus d'informations sur les tarifs et les disponibilités.</p>
  <hr className="h-px my-8 bg-gray-200 border-1 dark:bg-gray-700"></hr>
  </div>
</div> */}


            <div className="container"


            >
                <div className="description">
                    <div className="row">
                        <div className="col-6 icon-infos-right">
                            <i className="hp-icon fas fa-fw fa-arrow-circle-left"></i>
                            <strong>Check-out&nbsp;</strong>
                            : Avant 10H
                        </div>
                        <div className="col-6 icon-infos">
                            <i className="hp-icon fas fa-fw fa-arrow-circle-right"></i>
                            <strong>Check-in&nbsp;</strong>
                            : Après 17H
                        </div>
                        <div className="col-6 icon-infos-right">
                            <i className="hp-icon fas fa-fw fa-bed"></i>
                            <strong>Chambres: &nbsp;</strong>
                            3
                        </div>

                        <div className="col-6 icon-infos">
                            <i className="hp-icon fas fa-fw fa-bath"></i>
                            <strong>Salles de bains&nbsp;</strong>
                            : 2
                        </div>
                        <div className="col-6 icon-infos-right">
                            <i className="hp-icon fas fa-fw fa-paw"></i>
                            <strong>Animaux&nbsp;</strong>
                            : Admis
                        </div>
                        <div className="col-6 icon-infos">
                            <i className="hp-icon fa fa-users"></i>
                            <strong>Personnes: &nbsp;</strong>
                            7
                        </div>
                    </div>
                    <hr className="h-px my-8 bg-gray-200 border-1 dark:bg-gray-700"></hr>
                </div>
            </div>
            <div>
                <h1 className="titre" style={{
                    fontSize: "18pt"
                }}>Les détails </h1>

                <div className="row"
                     style={{
                         minHeight: "calc(100vh/2)",
                     }}
                >
                    <div className="item">
                        <p className="titre">En pratique !</p>
                        <div className="item-resp">
                        <ul style={{
                            listStyleType: "none",
                            fontSize: "14pt",
                            textAlign: "left",
                            paddingLeft: "5vw"
                        }}>
                            <li>Voyageurs : 7</li>
                            <li>Chambres : 3</li>
                            <li>Terrain cloturé : Hauteur 120cm</li>
                            {/* salut alex, je presume que cette contri va pas faire lnog feu :p, ta vu je l ai mis en rouge pour t aider a la retoruver vite fait bien fait */}

                            <li>Animaux : Admis</li>
                            <li>Panorama sur les montagnes</li>
                            <li>Propriétaires habitent l'étage de la maison</li>
                            <li>Piscine extérieure privée : 30m2</li>
                        </ul>
                        </div>
                    </div>
                    <div className="item resp"
                         style={{
                             backgroundImage: "url(" + presentationImg[0] + ") ",
                             backgroundSize: "cover",
                             backgroundPosition: "center",
                             backgroundRepeat: "no-repeat",
                             minHeight: "calc(100vh/2)",
                         }}
                    >
                    </div>
                </div>

                <div className="row"
                     style={{
                         minHeight: "calc(100vh/2)",
                     }}
                >

                    <div className="item resp"
                         style={{
                             backgroundImage: "url(" + presentationImg[7] + ") ",
                             backgroundSize: "cover",
                             backgroundPosition: "center",
                             backgroundRepeat: "no-repeat",
                             minHeight: "calc(100vh/2)",
                         }}
                    >
                    </div>
                    <div className="item">
                        <p className="titre">Installations</p>
                        <div className="item-resp">
                        <ul style={{
                            listStyleType: "none",
                            fontSize: "14pt",
                            textAlign: "left",
                            paddingLeft: "5vw"
                        }}>
                            <li>TV par Câble, smart TV</li>
                            <li>Alarme anti-incendie</li>
                            <li>Détécteur de monoxyde de carbone</li>
                            <li>Internet (Wifi)</li>
                        </ul>
                        </div>
                    </div>

                </div>
                <div className="row"
                     style={{
                         minHeight: "calc(100vh/2)",
                     }}
                >
                    <div className="item">
                        <p className="titre">Équipements</p>
                        <div className="item-resp">

                        
                        <ul style={{
                            listStyleType: "none",
                            fontSize: "14pt",
                            textAlign: "left",
                            paddingLeft: "5vw"
                        }}>
                            <li>Cuisinière électrique : 4 plaques de cuisson</li>
                            <li>Four, hotte, micro onde</li>
                            <li>Réfrigérateur, congélateur</li>
                            <li>Grille pain</li>
                            <li>Lave-linge et Sèche-linge</li>
                            <li>Aspirateur et serpillère</li>
                        </ul>
                        </div>
                    </div>
                    <div className="item resp"
                         style={{
                             backgroundImage: "url(" + presentationImg[6] + ") ",
                             backgroundSize: "cover",
                             backgroundPosition: "center",
                             backgroundRepeat: "no-repeat",
                             minHeight: "calc(100vh/2)"
                         }}
                    >
                    </div>
                </div>
                <div className="row"
                     style={{
                         minHeight: "calc(100vh/2)",
                     }}
                >
                    <div className={"item resp"}
                         style={{
                             backgroundImage: "url(" + presentationImg[8] + ") ",
                             backgroundSize: "cover",
                             backgroundPosition: "center",
                             backgroundRepeat: "no-repeat",
                             minHeight: "calc(100vh/2)"
                         }}
                    >
                    </div>
                    <div className="item">
                        <p className="titre">Chauffage / Énergie</p>
                         <div className="item-resp">

                         
                        <ul style={{
                            listStyleType: "none",
                            fontSize: "14pt",
                            textAlign: "left",
                            paddingLeft: "5vw"
                        }}>
                            <li>Isolé pour l'hiver</li>
                            <li>Poêle à bois</li>
                            <li>Bois de chauffage à votre disposition en quantitée raisonable</li>
                            <li>Pompe à chaleur</li>
                        </ul>
                        </div>
                    </div>
                </div>
                <div className="row"
                     style={{
                         minHeight: "calc(100vh/2)",
                     }}
                >
                    <div className="item">
                        <p className="titre">Extérieur</p>
                        <div className="item-resp">

                        
                        <ul style={{
                            listStyleType: "none",
                            fontSize: "14pt",
                            textAlign: "left",
                            paddingLeft: "5vw"
                        }}>
                            <li>Jardin : privé, cloturé</li>
                            <li>Parking gratuit : 3 places</li>
                            <li>Meubles de jardin</li>
                            <li>Barbecue à charbon</li>
                            <li>Table avec 8 chaises</li>
                            <li>Douche extérieure</li>
                        </ul>
                        </div>
                    </div>
                    <div className="item resp"
                         style={{
                             backgroundImage: "url(" + presentationImg[3] + ") ",
                             backgroundSize: "cover",
                             backgroundPosition: "center",
                             backgroundRepeat: "no-repeat",
                             minHeight: "calc(100vh/2)"
                         }}></div>
                </div>

            </div>
            <div className="row"
                 style={{
                     backgroundImage: "linear-gradient(rgba(0,0,0,0.5),rgba(0,0,0,0.6)), url(/img/vue3.jpg) ",
                     backgroundSize: "cover",
                     backgroundPosition: "center",
                     color: "white",
                     width: "100vw",
                     float: "left",
                     paddingTop: "30px",
                     paddingBottom: "30px",
                     minHeight: "70vh",
                     marginBottom: "10px"

                 }}
            >
                <div className="item"
                     style={{
                         paddingLeft: "10vw",
                         paddingRight: "10vh"

                     }}
                >
                    A proximité de la maison, vous trouverez de nombreuses activités pour vous divertir. Vous pourrez profiter du lac du Sautet situé à 600m, accessible à pieds via un chemin. Vous pourrez vous y baigner, faire du pédalo, du canoë, ou encore de la planche à voile. Il y a de nombreux sentiers pour les amateurs de randonnées, de balades ou de VTT. Vous pourrez également profiter des stations de ski, les plus proches sont situées à 25km (Super Dévoluy et La joue du loup). La tuile aux loups à 1km propose des activités équestres.
                    <br/>Le village se trouve à environ 1Km, vous y trouverez une petite supérette, une boucherie, une boulangerie, un bureau de tabac, un magasin de légumes bio, divers restaurants et la distillerie que vous pourrez visiter qui propose des dégustations. Il y a aussi un terrain de tennis, une plaine de jeux et un city stade pour les enfants. Le marché se déroule sur la place du village tous les jeudis matins.
                    <br/>Il y a également le sanctuaire de la Salette situé à 16Km et la ferme de St. Luce à 8Km.
                </div>
            </div>


            <GoogleMap/>
            <div className="contact">
                <hr className="h-px my-8 bg-gray-200 border-1 dark:bg-gray-700 contact-hr"></hr>
                <p>Une question ?</p>
                <i class="fa-sharp fa-regular fa-envelope"></i>
                <span className="text-center"> hapeidays@gmail.com</span>
            </div>
        </div>


    )
}

export default Home;