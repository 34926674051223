import React, { useState } from "react";
import SwiperCore, { A11y, EffectCoverflow, Navigation, Pagination, Scrollbar, Thumbs } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";

SwiperCore.use([EffectCoverflow, Navigation, Pagination, Thumbs]);

const CarouselV2 = ({images, NumberDisplayed}) => {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
    const [currentfullscreen, setCurrentfullscreen] = useState(null);
  const fullScreenStyle = () => {
    if (currentfullscreen) {
      return {
        display: 'block',
        position: 'fixed',
        top: '0',
        left: '0',
        bottom: '0',
        right: '0',
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.9)',
        zIndex: '1000',
        textAlign: 'center',
      };
    } else {
      return {
        display: 'none',
      };
    }
  }
  return (
    <div className="">
      <Swiper
        zoom={{maxRatio:3}}
        modules={[Navigation, Pagination, Thumbs, Scrollbar, A11y]}
        navigation
        effect
        speed={800}
        centeredSlides={true}
        slidesPerView={NumberDisplayed}
        loop
        pagination={{ clickable:true }}
        scrollbar={{ draggable: true }}
        className="mySwiper"
        thumbs={{ swiper: thumbsSwiper }}
      >
        {images.map((img, i) => {
          return (
            <SwiperSlide key={i} className="images-swiper" style={{ borderRight: '5px solid white', borderRadius:'10px' }}
            //add fullscreen image on click
            onClick={(e) => {
                e.preventDefault();
                setCurrentfullscreen(img);
            }}
            >
              <img src={img} style={{ width: '100%', height: 'auto', cursor:'pointer'}} alt="" />
            </SwiperSlide>
          );
        })}
      </Swiper>
      {/* <Swiper
        onSwiper={setThumbsSwiper}
        slidesPerView={5} // Set to an odd number
        freeMode={true}
        watchSlidesVisibility={true}
        watchSlidesProgress={true}
        className="mySwiper thumbs-swiper-container" // Add a new class for styling
      >
        {images.map((img, i) => {
          return (
            <SwiperSlide key={i} className="thumbs-swiper-slide">
              <img src={img} style={{ width: '100%', height: 'auto' }} alt="" />
            </SwiperSlide>
          );
        })}
      </Swiper> */}

        {/* fullscreen */}
        <div className="fullscreen"
             style={fullScreenStyle()}
                onClick={(e) => {
                e.preventDefault();
                setCurrentfullscreen(null)
                }}
        >
          <img src={currentfullscreen}
               style={{
                 maxWidth: '100%',
                 maxHeight: '100%',
                 margin: 'auto',
                    position: 'absolute',
                    top: '50%',
                    transform: 'translateY(-50%) translateX(-50%)',
                    left: '50%'



          }}
               alt="" />
          <div style={{
            position: "fixed",
            top: "10px",
            right: "15px",
            color: "white",
            cursor: "pointer"
          }} onClick={(e) => {
            e.preventDefault();
            setCurrentfullscreen(null)
          }}>X</div>
        </div>

    </div>
  );
};

export default CarouselV2;
