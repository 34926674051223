import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker, SingleDatePicker, DayPickerRangeController } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import "react-dates/initialize"; 
import React, { useEffect } from 'react';
import { useState } from 'react';
import { getDates } from '../helper/helper';
import moment from 'moment/moment';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import getStripe from '../lib/getStripe';
import { Grid } from 'swiper';
import { collection, addDoc, serverTimestamp, orderBy, query, onSnapshot } from "firebase/firestore";
import { db } from "../utils/firebase"
import { v4 as uuidv4 } from 'uuid';
import { async } from '@firebase/util';
import axios from 'axios';
const AdminDatePicker = ({data, blocked}) => {


  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const defaultFocusedInput = "null";
  const [focusedInput, setFocusedInput] = useState(defaultFocusedInput);


const isRangeBlocked = ({ startDate, endDate }) => {
  if (!startDate || !endDate) {
    return false; // no range selected, so no need to block any days
  }
  const start = startDate.clone();
  while (start.isSameOrBefore(endDate, 'day')) {
    if (isDayBlocked(start)) {
      return true;
    }
    start.add(1, 'day');
  }
  console.log(startDate, endDate)
  data(startDate, endDate)
  return false;
};


const isDayBlocked = (day) => {
  const disabledDates = blocked.map(dateString => new Date(dateString));
  const formattedDate = day.format('YYYY-MM-DD');
  const disabledDatesString = disabledDates.map(date => date.toISOString().substring(0, 10)); // convert Date objects to strings
  
  
  const currentDate = moment(); // Get the current date
  if (day.isBefore(currentDate, 'day')) {
    return true; // Block days in the past
  }
  return disabledDatesString.includes(formattedDate);
};


    return (
      
      <div>
        <p className='text-lg text-center mt-2 mb-2'>Sélectionnez les dates</p>
      
        <div className='AdminDateHeader'>
          <DateRangePicker
            startDate={startDate}
            startDateId="start_date_id"
            endDate={endDate}
            endDateId="end_date_id"
            onDatesChange={({startDate, endDate})=> {
              if (isRangeBlocked({startDate, endDate})){
                setStartDate(null);
                setEndDate(null);
              } else {
                setStartDate(startDate);
                setEndDate(endDate);
                
                
              }
            }}
            focusedInput={focusedInput || defaultFocusedInput}
            onFocusChange={(focusedInput) => setFocusedInput(focusedInput)}
            displayFormat={"DD-MM-YYYY"}
            startDatePlaceholderText={"Arrivé"}
            endDatePlaceholderText={"Départ"}
            anchorDirection={"right"}
            openDirection={"down"}
            isOutsideRange={isDayBlocked}
            isDayBlocked={isDayBlocked}
            />
        </div>
      </div>

    )
}

export default AdminDatePicker;