import { Cancel, Check } from "@mui/icons-material";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material"
import { useState } from "react"
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const AddPriceDialog = ({call, title, componentStyle, icon, openText, n, sd, ed, price, id}) => {
    const [open, setOpen] = useState(false);
    const [startDate, setStartDate] = useState(sd?new Date(sd):new Date());
    const [endDate, setEndDate] = useState(ed?new Date(ed): new Date());
    const [name, setName] = useState(n);
    const [newPrice, setNewPrice] = useState(price);
    const [invalidFormat, setInvalidFormat] = useState(true);

    const clearInput = () => {
      setName("")
      setNewPrice(0)
      setStartDate(new Date())
      setEndDate(new Date())
      setOpen(false);
    }


    const handleClose = () => {
        setOpen(false);
    }

    const handleConfirm = () => {
      let data;
      if (id != null){
        data = {
          "name":name,
          "startDate":startDate,
          "endDate":endDate,
          "price":newPrice,
          "_id":id
        }
        call(data)
        setOpen(false)
      } else {
        data = {
          "name":name,
          "startDate":startDate,
          "endDate":endDate,
          "price":newPrice,
        }
        call(data);
        clearInput()
      }
    }

    return (

        <div>
        {/* <Button className="addPrice" onClick={()=>{setOpen(true)}}>Ajouter</Button> */}
        <div className={componentStyle}>
              <button onClick={()=>{setOpen(true)}}>
                    <div>{icon} {openText}</div>
              </button>
        </div>

        <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {""}
          </DialogContentText>

          <div className="row priceDialog">
            <div className="col-md-6">
                
                <div>
                    <label for="name" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Nom</label>
                    <input type="text" id="name" onChange={(e)=>{setName(e.target.value)}} class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" value={name} placeholder="ex: Vacances aout" required/>
                </div>
            </div>
            <div className="col-md-6">
            <div>
                    <label for="first_name" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Prix</label>
                    <input type="number" id="first_name" onChange={(e)=>{setNewPrice(e.target.value)}} class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" value={newPrice} placeholder="montant €" required/>
                </div>
            </div>
            <div className="col-md-6">
                <label for="fromDate" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Du</label>

                <DatePicker selected={startDate} dateFormat="dd/MM/yyyy" onChange={(date) => setStartDate(date)} />
            </div>
            <div className="col-md-6">
              <label for="endDate" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Au</label>

                <DatePicker selected={endDate} dateFormat="dd/MM/yyyy" onChange={(date) => setEndDate(date)} />
            </div>
          </div>

        </DialogContent>
        <DialogActions>
          <div className="text-red-600 m-1 flex items-center justify-center gap-2 py-2 text-sm">
                                    <button onClick={handleClose}>
                                        <div><Cancel/>Annuler</div>
                                    </button>
              </div>

          <div className="text-yellow-600 m-1 flex items-center justify-center gap-2 py-2 text-sm">
                                    <button disabled={false} onClick={handleConfirm}>
                                        <div><Check/> Valider</div>
                                    </button>
              </div>

        </DialogActions>
      </Dialog>
        </div>
    )

}

export default AddPriceDialog;