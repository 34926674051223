import { Pin } from '@mui/icons-material';
 

const GoogleMap = () => {
    const position = {
        center: {
            lat: 44.8179,
            lng: 5.9411
          },
          zoom: 11
    }
    
    // Initialize and add the map

    return (
        <div id="ville">
            <iframe className='maps' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d22641.061763137095!2d5.9357795!3d44.818860750000006!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12cb32e358d76ef9%3A0x408ab2ae4be7b50!2s38970%20Corps%2C%20France!5e0!3m2!1sen!2sbe!4v1680270711951!5m2!1sen!2sbe">
            </iframe>
        </div>
    )


}

export default GoogleMap;