import { Grid, TextField, Button, FormControl, InputLabel, OutlinedInput, InputAdornment, IconButton } from "@mui/material";
import {signInWithEmailAndPassword} from "firebase/auth"
import { ControlPointDuplicate, Google, Label } from "@mui/icons-material";
import { useEffect, useRef, useState } from "react";
import { auth, db } from "../utils/firebase";
import { Visibility, VisibilityOff, AccountCircle } from "@mui/icons-material";
import { useAuthState } from "react-firebase-hooks/auth"
import MyDatePicker from "../Components/DatePick";
import { collection, addDoc, serverTimestamp, orderBy, query, onSnapshot, doc, deleteDoc, updateDoc } from "firebase/firestore";
import { toast, ToastContainer } from "react-toastify";
import { ConfirmationNumber } from "@mui/icons-material";
import AlertDialog from "../Components/ConfirmDialog";
import { formatDate, getCardColor, getColor, getDates, getStatusColor, isPaid } from "../helper/helper";
import axios from "axios";
import AdminDatePicker from "../Components/AdminDatePicker";
import { config } from "../Config";
import Price from "../Components/Prices";
import { useNavigate } from "react-router-dom";


const Admin = () => {
    // sign in with google

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const navigate = useNavigate();


    const loginWithEmailAndPassword = async (mail, psw) =>{
            await signInWithEmailAndPassword(auth, mail, psw).then(
                (userCredentials)=>{
                    setUsername("")
                    setPassword("")
                    const user = userCredentials.user
                    console.log(user)
                    toast.success("Authentification réussi", {
                        position: toast.POSITION.BOTTOM_CENTER,
                        autoClose:2000
                       })
                    navigate("/dashboard")

                }
            ).catch((error) => {
                setUsername("")
                setPassword("")
                toast.error("Email ou mot de passe incorrect", {
                    position: toast.POSITION.BOTTOM_CENTER,
                    autoClose:2000
                   })
                })

    }


    const handleClickShowPassword = () => setShowPassword((show) => !show);



    return (
        <div className="container admin">
            <ToastContainer/>

            <div className="left-admin">

            </div>
            <div className="right-admin">
             <div>
                <h1>Login</h1> 
             <div>
                 <form>

                    <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined" style={{margin:"5px"}}>
                            <InputLabel htmlFor="outlined-adornment-username">Username</InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-username"
                                type='text'
                                onChange={e => setUsername(e.target.value)}
                                required
                                

                                value={username}
                                endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                    aria-label="toggle username"
                                    onClick={handleClickShowPassword}
                                    edge="end"
                                    >
                                    <AccountCircle/>
                                    </IconButton>
                                </InputAdornment>
                                }
                                label="Password"
                            />
                        </FormControl>
                    <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined" style={{margin:"5px"}}>
                            <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-password"
                                type={showPassword ? 'text' : 'password'}
                                onChange={e => setPassword(e.target.value)}
                                required
                                

                                value={password}
                                endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    edge="end"
                                    >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                                }
                                label="Password"
                            />
                        </FormControl>


             <div className="signIn">
                 <Button variant="contained" size="large" onClick={()=> loginWithEmailAndPassword(username, password)} >
                     Sign in
                 </Button>
             </div>
             </form>
             </div>

                 </div>   
            </div>
            
            

        </div>

    )
}

export default Admin;